<template>
  <div class="h-100_">
    <content-header title="系统日志">
      <div class="d-f ai-c logs">
        <el-date-picker
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-DD"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          v-model="time"
        >
        </el-date-picker>
        <el-select
          style="margin: 0 10px"
          v-model="moduleName"
          placeholder="请选择"
          clearable
          @clear="search"
        >
          <el-option
            v-for="item in moduleNameList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button @click="search" type="primary">查询</el-button>
        <span style="margin: 0 10px">当前条数：{{ total }}</span>
        <el-button
          @click="exportData"
          :disabled="!(selection && selection.length)"
          type="primary"
          >导出</el-button
        >
      </div>
    </content-header>
    <content-table
      ref="table"
      :total="total"
      :columns="columns"
      :getTableData="getTableDataList"
      :tableList="tableList"
      needSelection
      @selectionChange="selectionChange"
    ></content-table>
  </div>
</template>

<script>
import ContentHeader from "@/components/contentHeader.vue";
import ContentTable from "@/components/contentTable.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("logs");
export default {
  name: "school",
  components: {
    ContentHeader,
    ContentTable,
  },
  data() {
    return {
      columns: [
        { label: "姓名", prop: "operatorName",width:"120" },
        { label: "手机号", prop: "phone" ,width:"120" },
        { label: "操作时间", prop: "createTime",width:"160"  },
        { label: "类型", prop: "moduleName",width:"80"  },
        { label: "请求参数", prop: "requestBody" },
        { label: "返回参数", prop: "responseParams" },
        { label: "内容", prop: "operateContent",width:"200"  },
      ],
      moduleName: "",
      time: [],
      moduleNameList: [
        { id: "学校管理", name: "学校管理" },
        { id: "白名单管理", name: "白名单管理" },
        { id: "通知公告", name: "通知公告" },
        { id: "安装员管理", name: "安装员管理" },
      ],
      selection: [],
    };
  },
  methods: {
    ...mapActions(["getTableData", "getExcel"]),
    getTableDataList(val) {
      this.getTableData(val);
    },
    search() {
      const { moduleName } = this;
      this.$refs.table.getDataInit({
        moduleName,
        startTime: this.time[0] || "",
        endTime: this.time[1] || "",
      });
    },
    selectionChange(val) {
      this.selection = val;
    },
    exportData() {
      this.getExcel({
        exportIds: this.selection.map((it) => it.id).join(","),
      });
    },
  },
  mounted() {
    this.$refs.table.getDataInit();
  },
  computed: {
    ...mapState(["total", "tableList"]),
  },
};
</script>
<style lang="scss" scoped>
.logs {
  button {
    min-width: 76px;
  }
}
</style>